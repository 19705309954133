import { useCallback } from 'react';

import { BookDefinition, BookMonopoly } from '@/components/common/Book';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { BookViewItem, SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { AiRecommendationItem, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { GenreHome3InfoPreset, GenreHomeBookItemRenderer } from '../../common/GenreHomeBook';
import { ListLayoutSection } from '../../common/ListLayoutSection';
import { SectionLayout } from '../../common/SectionLayout';

const AiRecommendGenreMap: { [key: string]: string } = {
  'romance-serial': 'romance_serial',
  'fantasy-serial': 'fantasy_serial',
  comics: 'comic',
  'bl-comics': 'bl_comic',
  'bl-novel': 'bl_novel',
  'bl-webtoon': 'bl_webtoon',
  'bl-webnovel': 'bl_webnovel',
};

export interface AiRecommendationProps {
  section: SectionByLayout<SectionLayoutType.AiRecommendation>;
}

const MIN_ITEM_COUNT = 3;

const convertToAiRecommendGenre = (path: string) => {
  const splitedPath = path.split('/');
  const genre = splitedPath[splitedPath.length - 1];
  const convertedGenre = AiRecommendGenreMap[genre] || genre;
  splitedPath[splitedPath.length - 1] = convertedGenre;
  return splitedPath.join('/');
};

export const AiRecommendation = ({ section }: AiRecommendationProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const userId = section.contents.title?.user_id || '';
  const postfix = section.contents.title?.postfix || 'AI 추천';

  const title = `${userId}${postfix}`;
  const aiRecommendDetailLink = section.more_details?.path
    ? convertToAiRecommendGenre(section.more_details.path)
    : undefined;

  const render = useCallback(
    ({ item, index }: { item: BookViewItem<AiRecommendationItem>; index: number }) => (
      <GenreHomeBookItemRenderer item={item} index={index} />
    ),
    [],
  );

  // TODO: [선독점 노출 강조] MonopolyBadge 커스텀 필요 여부 확인
  const presets = BookMonopoly.useMonopolyBadgePresets(GenreHome3InfoPreset);

  if (section.items.length < MIN_ITEM_COUNT) {
    return <></>;
  }

  return (
    <BookDefinition presets={presets} trackingData={sectionTrackingData.bookDefinition}>
      <SectionLayout title={title} link={aiRecommendDetailLink} paddingBottom={false}>
        <ListLayoutSection items={section.items} renderItem={render} />
      </SectionLayout>
    </BookDefinition>
  );
};
